<template>
	<div>
		<a-spin :spinning="loading">
			<a-form ref="form" :model="formState" name="form" @finish="onSearch">
				<!-- 搜索栏 -->
				<a-row>
					<a-form-item label="订单号" name="orderNo" class="ui-form__item">
						<a-input v-model:value="formState.orderNo" placeholder="请输入订单号"></a-input>
					</a-form-item>

					<a-form-item label="用户昵称" name="nickname" class="ui-form__item">
						<a-input v-model:value="formState.nickname" placeholder="请输入用户昵称"></a-input>
					</a-form-item>

					<a-form-item label="订单编号" name="businessNo" class="ui-form__item">
						<a-input v-model:value="formState.businessNo" placeholder="请输入订单编号"></a-input>
					</a-form-item>

					<a-form-item label="获得方式" name="fromType" class="ui-form__item">
						<a-select v-model:value="formState.fromType" placeholder="请选择获得方式" allow-clear
							style="width: 195px;">
							<a-select-option :value="1">自行购买</a-select-option>
							<a-select-option :value="2">用户兑换</a-select-option>
							<a-select-option :value="3">后台发放</a-select-option>
						</a-select>
					</a-form-item>
					
					<a-form-item label="发放人" name="sendUserName" class="ui-form__item">
						<a-input v-model:value="formState.sendUserName" placeholder="请输入发放人"></a-input>
					</a-form-item>

					<a-form-item label="开通时间" class="ui-form__item">
						<a-range-picker v-model:value="time"></a-range-picker>
					</a-form-item>
				</a-row>

				<a-row>
					<a-col :span="18">
						<a-button type="link" style="background: rgb(26, 182, 103); color: white;">导 出</a-button>
					</a-col>
					<a-col :span="6" style="text-align: right">
						<a-button style="margin-right: 10px" type="primary" html-type="submit">搜索</a-button>
						<a-button @click="reset">重置</a-button>
					</a-col>
				</a-row>

			</a-form>

			<div style="margin-top: 20px;">
				<a-table :pagination="pagination" :columns="columns" :dataSource="list" rowKey="id"
					:scroll="{ x: 1150 }">
					<template #bodyCell="{ column, record }">
						<template v-if="column.key === 'orderNo'">
							{{ record.orderNo || '-' }}
						</template>
						<template v-if="column.key === 'transactionId'">
							{{ record.transactionId || '-' }}
						</template>
						<template v-if="column.key === 'payment'">
							{{ record.payment || 0 }}
						</template>
						<template v-if="column.key === 'fromType'">
							{{ ['自行购买', '用户兑换', '后台发放'][record.fromType - 1] }}
						</template>
						<template v-if="column.key === 'createTime'">
							{{ transDateTime(record.createTime, 1) }}
						</template>
						<template v-if="column.key === 'expireTime'">
							{{ transDateTime(record.expireTime, 1) }}
						</template>
						<template v-if="column.key === 'userInfo'">
							<!-- <div>用户id：{{ record.userId || '-' }}</div> -->
							<div>用户昵称：{{ record.userNickname || '-' }}</div>
							<div>用户手机号：{{ record.userPhone || '-' }}</div>
						</template>
						<template v-if="column.key === 'senderNickname'">
							{{ record.senderNickname || '-' }}
						</template>
						<template v-if="column.key === 'isDisbaled'">
							<div>{{ record.memberStatus === 0 ? '已禁用' : '已启用' }}</div>
						</template>
						<template v-if="column.key === 'action'">
							<a-dropdown :trigger="['click', 'hover']">
								<a-button>操作 <Icon icon="DownOutlined"></Icon></a-button>
								<template #overlay>
									<a-menu>
										<div v-if="record.memberStatus === 0" @click="onDisabled(record)">
											<a-menu-item>
												启用
											</a-menu-item>
										</div>
										<div v-else @click="onDisabled(record)">
											<a-menu-item>
												禁用
											</a-menu-item>
										</div>
										<div>
											<a-menu-item>
												退款
											</a-menu-item>
										</div>
									</a-menu>
								</template>
							</a-dropdown>
						</template>
					</template>
				</a-table>
			</div>
		</a-spin>
	</div>
</template>

<script>
	import { getUserSpecialMemberRecord } from '@/service/modules/report.js';
	import { switchSpecialMember } from '@/service/modules/member.js';
	export default {
		data() {
			return {
				showModal: false,
				isEdit: false,
				isSee: false,
				id: 0,
				loading: false,
				time: [],
				searchData: {},
				modelRef: {},
				formState: {},
				columns: [{
					title: '用户信息', //（商品、演出、影片、卖品）
					key: 'userInfo',
				}, {
					title: '开通时间',
					key: 'createTime',
				}, {
					title: '到期时间',
					key: 'expireTime'
				}, {
					title: '获得方式',
					key: 'fromType',
					width: 150
				}, {
					title: '赠送人/发放人',
					key: 'senderNickname',
				}, {
					title: '状态',
					key: 'isDisbaled',
					width: 100
				}, {
					title: '操作',
					key: 'action',
					fixed: 'right',
					align: 'center',
					width: 100
				}],
				list: [],
				pagination: {
					showSizeChanger: true,
					showQuickJumper: true,
					size: "middle",
					pageSizeOptions: ['10', '20', '50', '100', '500'],
					showTotal: (total) => {
						return "共 " + total + " 条";
					},
					total: 0,
					current: 1,
					pageSize: 10,
					onChange: (page) => {
						this.pagination.current = page;
						this.getData();
					},
					onShowSizeChange: (page, pageSize) => {
						this.pagination.current = 1;
						this.pagination.pageSize = pageSize;
						this.getData();
					}
				},
			}
		},
		created() {
			this.getData();
		},
		methods: {
			reset() {
				this.time = [];
				this.$refs.form.resetFields();
				this.onSearch();
			},
			onSearch() {
				this.pagination.current = 1;
				this.searchData = this.$deepClone(this.formState);
				if (this.time && this.time.length) {
					this.searchData.createStartTime = this.time[0].startOf('days').unix();
					this.searchData.createEndTime = this.time[1].endOf('days').unix();
				}
				this.getData();
			},
			async getData() {
				this.loading = true;
				let ret = await getUserSpecialMemberRecord({
					page: this.pagination.current,
					pageSize: this.pagination.pageSize,
					...this.searchData
				})
				this.loading = false;
				if (ret.code === 200) {
					this.list = ret.data.list;
					this.pagination.total = ret.data.totalCount;
				}
			},
			onDisabled(item) {
				this.$confirm({
					title: '提示',
					content: `确定${ item.memberStatus ? '禁用' : '启用' }该用户特色会员吗？`,
					onOk: async ()=> {
						this.loading = true;
						let ret = await switchSpecialMember({
							userId: item.userId,
							status: item.memberStatus ? 0 : 1
						})
						this.loading = false;
						if (ret.code === 200) {
							this.$message.success('操作成功')
							this.getData();
						}
					}
				})
			}
		}
	}
</script>

<style scoped>
	.ui-form__item {
		margin-right: 30px;
	}
</style>